import { isMobileWindow } from "../site";

const tabToggle = () => {
  if ($(".js-tabs")) {
    const tabBtn = $(".js-tablink");

    tabBtn.on("click", function () {
      const tabId = $(this).attr("data-id");

      if (!$(this).hasClass("active")) {
        tabBtn.removeClass("active");
        $(this).addClass("active");

        $(".js-tabcontent").hide();
        $("#" + tabId).fadeIn("slow");
      }
    });
  }
};

const moreBtn = () => {
  if ($(".js-more-show")) {
    const btnShow = $(".js-more-show");
    const btnHide = $(".js-more-hide");

    btnShow.each(function () {
      $(this).on("click", function () {
        $(this).parent().siblings(".js-more-box").slideDown();
        $(this).removeClass("visible");
        $(this).siblings().addClass("visible");
      });
    });

    btnHide.each(function () {
      $(this).on("click", function () {
        $(this).parent().siblings(".js-more-box").slideUp();
        $(this).removeClass("visible");
        $(this).siblings().addClass("visible");
      });
    });
  }
  if ($(".js-slide-wrap")) {
    const btnShow = $(".js-slide-wrap");
    const $sectionSlides = $(".section-how-it-works-wrap");
    if ($sectionSlides) {
      var $elH = $(".abs-sm").innerHeight();
      $sectionSlides.css("padding-bottom", $elH);
    }
    btnShow.each(function () {
      let mobileView = isMobileWindow();
      if (mobileView) {
        const slideItems = $(this).prev().find(".slide-sm");
        if ($(this).hasClass("active")) {
          $(this)
            .find(".data-text-show-more")
            .removeClass("hide")
            .siblings(".text")
            .addClass("hide");
          $(this).removeClass("active");
          slideItems.each(function () {
            var item = $(this);
            item.slideUp();
          });
        }
      }

      $(this).on("click", function () {
        const slideItems = $(this).prev().find(".slide-sm");
        if ($(this).hasClass("active")) {
          // $(this).prev().slideUp();
          $(this)
            .find(".data-text-show-more")
            .removeClass("hide")
            .siblings(".text")
            .addClass("hide");
          $(this).removeClass("active");
          slideItems.each(function () {
            var item = $(this);
            item.slideUp();
          });
        } else {
          // $(this).prev().slideDown();
          $(this).addClass("active");
          $(this)
            .find(".data-text-hide-more")
            .removeClass("hide")
            .siblings(".text")
            .addClass("hide");
          slideItems.each(function () {
            var item = $(this);
            item.slideDown();
          });
        }
      });
    });
  }
  if ($(".js-product-read-more").length > 0) {
    const wrap = $(".js-product-wrap");
    wrap.each(function (index) {
      const $wrap = $(wrap[index]);

      let btn = $wrap.next().find(".js-product-read-more");
      const excerpt = $wrap.find(".js-product-excerpt");
      const accordion = $wrap.find(".js-product-accordion");
      const paragraphFirst = $wrap.find(".excerpt-text-more p:first-child");
      const accordionHeight = accordion.height() + "px";
      const excerptHeight = excerpt.height() + "px";

      const toggleExcerpt = function () {
        if ($wrap[0].classList.contains("excerpt")) {
          $wrap.css("max-height", accordionHeight);
          $wrap.removeClass("excerpt");
          btn.removeClass("more");
          $(".js-product-excerpt p:last-child").replaceWith(function () {
            return $("<span />", {
              html: $(".js-product-excerpt p:last-child").html(),
            });
          });

          paragraphFirst.replaceWith(function () {
            return $("<span />", { html: paragraphFirst.html() });
          });
        } else {
          $wrap.css("max-height", excerptHeight);
          $wrap.addClass("excerpt");
          btn.addClass("more");
          $(".js-product-excerpt span:last-child").replaceWith(function () {
            return $("<p />", {
              html: $(".js-product-excerpt span:last-child").html(),
            });
          });
        }
      };

      $wrap.css("max-height", excerpt.height() + "px");

      const btnClone = btn[0].cloneNode(true); // cloning node to remove all event listeners before adding new one on window resize to prevent having multiple click event listeners active
      btn[0].parentNode.replaceChild(btnClone, btn[0]);
      btn = $(btnClone);

      btn.on("click", toggleExcerpt);

      $(window).on("resize", function () {
        const accordionHeight = accordion.height() + "px";
        const excerptHeight = excerpt.height() + "px";

        if ($wrap[0].classList.contains("excerpt")) {
          $wrap.css("max-height", excerptHeight);
        } else {
          $wrap.css("max-height", accordionHeight);
        }
      });
    });
  }
};
const basicSlider = () => {
  const slider = $("[data-elem='basic-slider']");
  if (slider) {
    slider.slick({
      dots: true,
      infinite: false,
      arrows: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      slidesToShow: 1,
    });
  }
};
const newsSlider = () => {
  if ($("[data-elem='news-slider']")) {
    $("[data-elem='news-slider']").slick({
      dots: true,
      infinite: false,
      arrows: false,
      slidesToShow: 3,
      slidesToScroll: 1,
      slidesToShow: 3,
      centerMode: false,
      responsive: [
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
          },
        },
        {
          breakpoint: 575,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    });
  }
};
const advantagesSlider = () => {
  if ($(".section-advantages__slider")) {
    $(".section-advantages__slider").slick({
      dots: true,
      infinite: false,
      speed: 1000,
      slidesToShow: 3,
      slidesToScroll: 3,
      responsive: [
        {
          breakpoint: 991,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
          },
        },
        {
          breakpoint: 575,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    });
  }
};

const accordionProduct = () => {
  if ($(".js-accordion")) {
    $(".js-accordion__header").on("click", function () {
      if ($(this).hasClass("active")) {
        $(this).removeClass("active");
        $(".js-accordion__body").slideUp();
      } else {
        $(".js-accordion__header").removeClass("active");
        $(".js-accordion__body").slideUp();
        $(this).addClass("active");
        $(this).next().slideDown();
      }
    });
  }
};

const setColumnHeight = () => {
  const lists = $(".js-two-columns");
  lists.each(function (i) {
    const list = $(lists[i]);
    const items = list.find("> li");
    const N = items.length;
    const halfN = ~~((N + 1) / 2);
    const setHeight = function () {
      let leftColHeight = 0;
      for (let i = 0; i < halfN; i++) {
        leftColHeight += $(items[i]).outerHeight(true);
      }
      let rightColHeight = 0;
      for (let i = halfN; i < N; i++) {
        rightColHeight += $(items[i]).outerHeight(true);
      }
      list.css("max-height", "");
      if (window.innerWidth >= 991) {
        list.css(
          "max-height",
          leftColHeight > rightColHeight
            ? leftColHeight + 20 + "px"
            : rightColHeight + 20 + "px"
        );
      }
    };

    setHeight();
    $(window).on("resize", function () {
      setHeight();
    });
  });

  // const imgToZoom = $(".js-product-zoom")
  const popup = $(".js-product-popup");
  const close = $(".js-product-close-icon");
  const open = $(".js-product-open");
  const info = $(".js-product-info");
  if (open.length >= 1) {
    open.each(function (i) {
      const thisOpen = open[i];
      const thisID = Array.from(thisOpen.classList).filter((className) =>
        className.includes("id-")
      )[0];
      const thisPopup = popup.parent().find("." + thisID);
      $(thisOpen).on("click", function () {
        thisPopup.addClass("is-active");
        $("body").css("overflow", "hidden");
        info.css("display", "");
      });
      thisPopup.on("touchstart", function (e) {
        if (e.touches.length > 1) {
          setTimeout(() => {
            info.css("display", "none");
          }, 600);
        }
      });
      thisPopup.on("mouseup", function (e) {
        info.css("display", "none");
      });
      close.on("click", function () {
        popup.removeClass("is-active");
        $("body").css("overflow", "");
      });
    });
  } else {
    open.on("click", function () {
      popup.addClass("is-active");
      $("body").css("overflow", "hidden");
      info.css("display", "");
    });
    close.on("click", function () {
      popup.removeClass("is-active");
      $("body").css("overflow", "");
    });
    popup.on("touchstart", function (e) {
      if (e.touches.length > 1) {
        setTimeout(() => {
          info.css("display", "none");
        }, 600);
      }
    });
    popup.on("mouseup", function (e) {
      info.css("display", "none");
    });
  }
};
const popupSlider = () => {
  $(".js-modal-gallery").on("click", function (e) {
    const longSelector = $(this).parent().next();

    longSelector.find(".slider-gallery__inner").slick({
      infinite: false,
      arrows: true,
      prevArrow: longSelector.find(".slider-gallery__button--prev"),
      nextArrow: longSelector.find(".slider-gallery__button--next"),
      slidesToShow: 1,
      slidesToScroll: 1,
      adaptiveHeight: true,
      asNavFor: longSelector.find(".slider-gallery__nav-inner"),
    });

    longSelector.find(".slider-gallery__nav-inner").slick({
      infinite: false,
      slidesToScroll: 1,
      asNavFor: longSelector.find(".slider-gallery__inner"),
      focusOnSelect: true,
      mobileFirst: true,
      centerMode: false,
      arrows: false,
      variableWidth: true,
      responsive: [
        {
          breakpoint: 769,
          settings: {
            variableWidth: false,
            slidesToShow: 8,
            centerMode: false,
            vertical: true,
            verticalSwiping: true,
          },
        },
      ],
    });

    lightbox.option({
      disableScrolling: true,
    });
  });

  $(".js-tablink").on("click", function () {
    if ($(".slider-gallery__inner.slick-slider").length > 0) {
      $(".slider-gallery__inner.slick-slider").slick("unslick");
    }

    if ($(".slider-gallery__nav-inner.slick-slider").length > 0) {
      $(".slider-gallery__nav-inner.slick-slider").slick("unslick");
    }
  });
};

const showAllItems = () => {
  const wrapper = $(".js-product-accordion-wrapper");
  const list = $(".js-product-accordion-list");
  const toggle = $(".js-product-accordion-show-all");
  toggle.on("click", function () {
    const height = list.height();
    wrapper.css("max-height", height + "px");
    $(this).hide();
  });
};

const changeVideoPadding = () => {
  // const video = $(".js-video-big");
  // const changePadding = function () {
  //     const width = $(window).width();
  //     if ((773 / 1920) * width < 375) {
  //         video.css("padding-top", "375px");
  //     } else {
  //         video.css("padding-top", "");
  //     }
  // };
  // changePadding();
  // $(window).on("resize", changePadding);
};

const setImgToMiddle = () => {
  const item = $(".js-services-item");
  const img = item.find("img");
  const p = img.closest("p");
  p.css("display", "flex");
};
const tableComparison = () => {
  const wrapper = document.querySelector(".js-comparison");
  if (wrapper) {
    const mobileList = document.querySelector(".js-mobile-list");
    const header = document.querySelector(".js-comparison-header");
    const items = document.querySelectorAll(".js-comparison-item");
    const mobileHead = document.querySelectorAll(".js-comparison-list-item");
    items.forEach((item, index) => {
      mobileHead[index].style.height = `${item.clientHeight}px`;
      mobileHead[index].style.width = `${item.clientWidth}px`;
    });
    mobileList.style.paddingTop = `${header.clientHeight}px`;
  }
};

const resizeStickyMenuItems = () => {
  const stickyMenu = $(".scrollbar-js");
  const container = $(stickyMenu.find(".scrollbar-js-container")[0]);
  const items = stickyMenu.find(".scrollbar-js-item");

  let itemsWidth = 0;
  const containerWidth = container.width();
  items.each(function (i) {
    const item = $(items[i]);
    itemsWidth += item.width();
  });
  if (itemsWidth > 0.99 * containerWidth) {
    container.addClass("no-margins");
  } else {
    container.removeClass("no-margins");
  }
};

const subproductsToggle = () => {	
	const elemToggle = $(".js-toggle-subproducts");

  elemToggle.on("click", function (e) {
    e.preventDefault();
      const dataEL = $(this).attr("data-toggle-subproducts");
      const el = $("[data-subproducts-parent='" + dataEL +"']"); 
      if (el){
        
        const subproducts = el.find('.subproducts-row'); 
        const eloOfset =  el.offset();
        const elParentOffset =  el.parent().offset();
        console.log(eloOfset.left)
        console.log(elParentOffset)
        subproducts.css( "left", -eloOfset.left + 'px' );

        if(el.hasClass('subproducts-show')) {       
          el.removeClass("subproducts-show");          
          el.css( "padding-bottom", "0" );
        }
        else {
          el.siblings().removeClass("subproducts-show");
          el.addClass("subproducts-show");
          el.siblings().css( "padding-bottom", "0" );
          el.css( "padding-bottom", subproducts.innerHeight() + 64 + 'px' );
        }    
      }
    });	
	
};
export {
  tabToggle,
  newsSlider,
  basicSlider,
  moreBtn,
  advantagesSlider,
  accordionProduct,
  setImgToMiddle,
  setColumnHeight,
  showAllItems,
  changeVideoPadding,
  popupSlider,
  tableComparison,
  resizeStickyMenuItems,
  subproductsToggle
};
