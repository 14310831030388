const getCoords = (elem) => {
    if (elem) {
        let box = elem.getBoundingClientRect();

        return box.top + pageYOffset;
    }
};

class Comparison {
    constructor() {
        this.elements = document.querySelectorAll('[data-js="comp-start"]');

        this.accordions = document.querySelectorAll('[data-js="comp-acc"]');

        this.changeTransform = this.changeTransform.bind(this);
        this.addScrollListeners = this.addScrollListeners.bind(this);
        this.addAccordionListeners = this.addAccordionListeners.bind(this);

        this.init();
    }

    hideImages(element) {
        element
            .querySelectorAll("img")
            .forEach((item) => (item.style.display = "none"));
        const thed = element.querySelector(".section-comp__thed");
        if (thed) {
            thed.style.display = "none";
        }
    }

    showImages(element) {
        element
            .querySelectorAll("img")
            .forEach((item) => (item.style.display = ""));
        const thed = element.querySelector(".section-comp__thed");
        if (thed) {
            thed.style.display = "";
        }
    }

    changeTransform() {
        this.elements.forEach((item) => {
            const head = item.querySelector('[data-js="comp-element"]');
            const end = item.querySelector('[data-js="comp-end"]');

            const headHeight = head.clientHeight;
            const headerHeight = document.querySelector(".p-nav-sticky")
                .clientHeight;

            const startCoords = getCoords(item);
            const endCoords = getCoords(end);
            const scrollNow = pageYOffset + headerHeight - 16;

            if (startCoords <= scrollNow) {
                if (endCoords >= scrollNow + headHeight) {
                    this.hideImages(head);

                    head.style.cssText = `transform: translateY(${
                        scrollNow - startCoords
                    }px);`;
                } else if (endCoords < scrollNow + headHeight) {
                    head.style.cssText = `transform: translateY(${
                        endCoords - startCoords - headHeight
                    }px);`;
                }
            } else {
                head.style.cssText = ``;
                this.showImages(head);
            }
        });
    }

    addScrollListeners() {
        if (document.documentElement.clientWidth < 991) {
            document.addEventListener("scroll", this.changeTransform);
        }
        window.addEventListener("resize", () => {
            if (document.documentElement.clientWidth < 991) {
                document.addEventListener("scroll", this.changeTransform);
            } else {
                document.removeEventListener("scroll", this.changeTransform);
                this.elements.forEach((item) => {
                    this.showImages(item);
                    item.querySelector(
                        '[data-js="comp-element"]'
                    ).style.cssText = "";
                });
            }
        });
    }

    toggleBody(body, initialHeight) {
        if (body.classList.contains("active")) {
            setTimeout(() => body.classList.remove("active"), 1000);
            body.style.height = initialHeight + "px";
        } else {
            body.classList.add("active");
            body.style.height = 0;
        }
    }

    addAccordionListeners(item) {
        const head = item.querySelector('[data-js="acc-head"]');
        const body = item.querySelector('[data-js="acc-body"]');
        const bodyHeight = body.clientHeight;
        body.style.height = bodyHeight + "px";
        head.addEventListener("click", () => {
            head.classList.toggle("active");
            this.toggleBody(body, bodyHeight);
        });
    }

    init() {
        this.addScrollListeners();
        this.accordions.forEach(this.addAccordionListeners);
    }
}

export default Comparison;
