const translationsEL = document.querySelector('.js-search-data')
const notEnoughData = translationsEL.getAttribute('data-not-enough')
const emptyResultsData = translationsEL.getAttribute('data-empty-results')
const suggestionsData = translationsEL.getAttribute('data-suggestions')
export const createContent = (arr, sugs) => {
  let string = ''
  let i = 0
  let cat = ''
  if (arr.length || sugs.length) {
    string = `<div class="results-wrapper"><div class="results-wrapper__title">${suggestionsData}</div>`

    for (const value of sugs) {
      var suggest = value.suggest
      var offset = 0

      for (const appearance of value.appearance) {
        suggest =
          suggest.substring(0, appearance.start_index + offset) +
          '<strong>' +
          suggest.substring(
            appearance.start_index + offset,
            appearance.end_index + offset,
          ) +
          '</strong>' +
          suggest.substring(appearance.end_index + offset, suggest.length)
        offset += 17
      }

      string += '<div class="suggest-item">' + suggest + '</div>'
    }

    for (const value of arr) {
      if (value.cat != 'Bez kategorii' && value.cat !== null) {
        cat = '<span class="category">' + value.cat + '</span>'
      }
      if (i === 0 || value['post_type'] !== arr[i - 1]['post_type']) {
        string += `<h2 class="results-wrapper__header">${value.post_type}</h2><ul class="results-wrapper__list">`
      }
      let clientLink
      if (value.post_type === 'Obsługa Klienta') {
        const urlArr = value.link.split('/')
        const linkAtr = urlArr.slice(-2)
        clientLink = `/obsluga-klienta?cat=${linkAtr[0]}&post=${linkAtr[1]}`
      }
      string += `<li>${cat}<a href='${
        clientLink ? clientLink : value.link
      }'><span class="post-title">${value.title}</span></a></li>`
      if (
        (i === 0 && i === arr.length - 1) ||
        i === arr.length - 1 ||
        value['post_type'] !== arr[i + 1]['post_type']
      ) {
        string += `</ul>`
      }
      i++
    }
    string += `</div>`
  }
  return string
}
export const createEmptyResult = () =>
  `<div class="results-wrapper"><h3 class="no-content">${emptyResultsData}</h3></div>`

export const createNotEnough = () =>
  `<div class="results-wrapper"><h3 class="no-content">${notEnoughData}</h3></div>`

export const createNoFound = (arr) =>
  `<div class="results-wrapper"><h3 class="no-content">${arr[0].title}</h3></div>`
