// eslint-disable-next-line no-unused-vars
import ClientsMenu from './clientsMenu/clientsMenu'
import './vendors/js-cookie.js'  

let isMobile
const checkMobile = () => {
  isMobile = isMobileWindow()
}
const isMobileWindow = () => {
  const mq = window.matchMedia('(max-width: 992px)')
  return mq.matches
}
const setHeightPostHeader = () => {
  const single_header = $('.section-hero-large__area.no-height')
  if (single_header.length > 0) {
    const single_header_content = single_header.find(
      '.section-hero-large__area-inner',
    )
    const single_header_contentH = single_header_content.innerHeight()

    single_header.css('min-height', single_header_contentH + 60)
  }
}
const readMore = () => {
  // 'rozwiń całość' / 'zwiń' w checkboxach
  const elShow = $('.readmore-show')
  const elHide = $('.readmore-hide')
  const elText = $('.readmore-text-more')
  const excerpt = $('.js-form-checkbox-excerpt')
  elText.addClass('hide')
  elShow.each(function () {
    $(this).on('click', function (e) {
      e.preventDefault()
      $(this).siblings('.readmore-dots').addClass('hide')
      $(this).parent().find('.readmore-text-more').removeClass('hide')
      $(this).addClass('hide')
      $(this).siblings('.js-form-checkbox-excerpt').addClass('hide')
    })
  })
  elHide.each(function () {
    $(this).on('click', function (e) {
      e.preventDefault()
      $(this).parent().siblings('.readmore-dots').removeClass('hide')
      $(this).parent().addClass('hide')
      $(this).parent().prev().removeClass('hide')
      $(this).parent().siblings('.js-form-checkbox-excerpt').removeClass('hide')
    })
  })
}

const hamburger = () => {
  const btn = $('.toggle-button')
  const btnCloseLabel = btn.attr('data-close-menu')
  const btnOpenLabel = btn.find('.toggle-button-label').text()
  const menu = $('#toggle-menu-js')
  const submenuItems = $('.menu-item-has-children')
  const submenuBack = $('.nav-drop-item--back')


  if (isMobile) {
    var open = false
    if ($('body').hasClass('menu-opened')) {
      open = true
    }
    btn.on('click', function () {
      if (open === false) {
        open = true
        $('body').addClass('menu-opened')
        menu.addClass('show-menu')
        btn.addClass('active')
        btn.find('.toggle-button-label').text(btnCloseLabel)
      } else {
        open = false
        $('body').removeClass('menu-opened')
        menu.removeClass('show-menu')
        btn.removeClass('active')
        btn.find('.toggle-button-label').text(btnOpenLabel)
      }
    })

    $(submenuItems).each(function () {
      $(this).on('click', function (e) {
        if($(e.target).hasClass("menu-item-has-children")) {
          e.preventDefault()
          const itemID = $(this).data("item-id")
          $("[data-parent-id='" + itemID +"']").addClass("active")   
        }
        else {
          return;
        }
       
      })
    })

    $(submenuBack).each(function () {
      $(this).on('click', function (e) {
        console.log("dada");
        e.preventDefault()
        const itemID = $(this).data("item-id")
        $("[data-parent-id='" + itemID +"']").removeClass("active")     
      })
    })
  
  }
}

const stickyMainMenu = () => {
  var $header = $('.main-menu__bottom')
  var $navTop = $('.main-menu__top')
  // var $hideEl = $(".hide-on-sticky-js");
  var $menuElH = $('.main-menu').innerHeight()
  var $headerPlaceholder = $('.header-placeholder')
  var scrollBar = $('.scrollbar-js')
  var scrollbarSticky = scrollBar.next().offset()
 
  function checkPosition() {
    var yScrollAxis = Math.floor(window.pageYOffset)
    if (yScrollAxis > $menuElH / 2) {
      $header.addClass('fixed')
      $navTop.addClass('is-sticky')
    } else {
      $header.removeClass('fixed')
      $navTop.removeClass('is-sticky')
    }
    if (scrollBar.length > 0) {
      if (window.pageYOffset >= scrollbarSticky.top) {
        scrollBar.addClass('sticky')
      } else {
        scrollBar.removeClass('sticky')
      }
    }
  }
  $(window).on('scroll', function () {
    checkPosition()
  })

  // Adding element resize listener to .main-menu, in which .header-placeholder's height is changed
  // according to current .main-menu content height
  const mainMenu = document.querySelector('.main-menu')
  const placeholder = $headerPlaceholder.get(0)
  const resizeObserver = new ResizeObserver((elements) => {
    for (let element of elements) {
      const mainMenuHeight = element.contentRect.height
      const currentHeight = parseInt(
        placeholder.style.paddingBottom.replace('px', ''),
      )
      if (mainMenuHeight > currentHeight)
        placeholder.style.paddingBottom = mainMenuHeight + 'px'
    }
  })
  resizeObserver.observe(mainMenu)
}

const loadLessButtonScroll = () => {

  const loadMoreInput = document.querySelectorAll('#load-more');
  loadMoreInput.forEach((input) => {
    const id = input.getAttribute('id');
    const label = document.querySelector(`[for='${id}']`);
  label.addEventListener('click', (e) => {
    console.log(label.offsetTop)
    if(input.checked) {
      setTimeout(function() {
        window.scrollTo({
          top: input.parentElement.offsetTop,
          behavior: "smooth"
      });
      }, 300);  
    }
  });    
  });
}

const linksDisabled = () => {
  document.querySelectorAll('.isDisabled').forEach((link) =>
    link.addEventListener('click', function (event) {
      event.preventDefault()
    }),
  )
}

const footerMenu = () => {
  const footerMenuBtn = $('.footer-top__h4')
  // const footerMenu = $(".footer-top__toggle-menu");

  footerMenuBtn.on('click', function (e) {
    if (isMobile) {
      e.preventDefault()
      $(this).next().slideToggle()
      $(this).children('.footer-top-arrow').toggleClass('up')
    }
  })
}

const aboutUsText = () => {
  const text = document.getElementById('about-text')
  const showMoreHtml = $('.o-section-tmobile-video__button--show-more')
  const showLessHtml = $('.o-section-tmobile-video__button--show-less')
  const accordion = $('.o-section-tmobile-video__accordion')
  const toggleBtn = $('#about-text-btn')

  toggleBtn &&
    toggleBtn.on('click', () => {
      if (text.classList.contains('exercept')) {
        // pobieranie wysokości elementu z tekstem "accordion" i nadanie wrapperowi tego elementu tej wysokości
        const height = accordion.height()
        text.style.maxHeight = height + 'px'
        text.classList.remove('exercept')
      } else {
        text.style.maxHeight = ''
        text.classList.add('exercept')
      }
      // zmiana tekstu buttona w zalezności od tego, czy tekst jest rozwinięty, czy nie:
      showMoreHtml.toggleClass('hide')
      showLessHtml.toggleClass('hide')
    })

  text &&
    $(window).on('resize', function () {
      const height = accordion.height()
      !text.classList.contains('exercept') &&
        (text.style.maxHeight = height + 'px')
    })
}

const removeVideoCovering = () => {
  const video = $('.js-video-video')

  if (video.length > 0) {
    video.each(function (i) {
      const thisVideo = $(video[i])
      const player = thisVideo.find('#js-video-player')[0]
      const panorama = thisVideo.find('#js-panorama')[0]
      const covering = thisVideo.find('.js-video-cover')
      thisVideo.on('click touch', function () {
        covering.css('display', 'none')
        if (player) {
          player.src += '?autoplay=1'
          player.style.display = 'block'
        }
        if (panorama) {
          panorama.src += '&autoLoad=true'
        }
      })

      // const playBtn = $(".js-play-video");
      // const player_src = player.src + "?autoplay=1";
      // playBtn.on("click", function () {
      //     console.log(player_src);
      //     player.src = player_src;
      //     $(this).hide();
      // });

      if (player) {
        const closeVideoModal = $('.js-modal-close')
        closeVideoModal.on('click', function () {
          player.src = player.src.split('?autoplay')[0]
          covering.css('display', '')
        })
      }
    })
  }
}

const playVideo = () => {}

const scrollToSection = () => {
  const scrollTo = $('[data-scroll-to]')

  // Warunek pozwoli unieknąć bledow w konsoli
  scrollTo.length > 0 &&
    scrollTo.each(function () {
      const $menuElH = $('.main-menu__bottom').innerHeight()
      const $scrollBar = $('.scrollbar-js').innerHeight()

      $(this).on('click', function (e) {
        e.preventDefault()
        // $(this).parent().addClass("current");
        // $(this).parent().siblings().removeClass("current");
        let scrollToSection = $(this).attr('data-scroll-to')
        if ($('#' + scrollToSection).length > 0) {
          let offsetTopScroll = $('#' + scrollToSection).offset().top - $menuElH
          $('html, body').animate(
            {
              scrollTop: offsetTopScroll,
            },
            1000,
          )
        }
      })
    })
}
// const initCookies = () => {
//     var informationCookie = {
//         start: function () {
//             if (!Cookies.get("using_cookies_law")) {
//                 this.show();
//             }
//         },

//         show: function () {
//             var testPar = $(".cookie-info-widget").html();
//             this.mount(
//                 '<div id="js_cookies-information" class="js_cookies-information cookies"><div class="o-container">' +
//                     testPar +
//                     "</div></div>"
//             );
//         },

//         mount: function (cookiesContentHTML) {
//             document.body.insertAdjacentHTML("beforeend", cookiesContentHTML);

//             this.accept = document.querySelectorAll(".js_cookies-close");

//             this.bindClick();
//         },

//         bindClick: function () {
//             for (var index = 0; index < this.accept.length; index++) {
//                 this.accept[index].addEventListener("click", function (e) {
//                     e.preventDefault();
//                     Cookies.set("using_cookies_law", 1, { expires: 365 });
//                     document
//                         .getElementById("js_cookies-information")
//                         .classList.add("js_cookies-information--hide");
//                     return false;
//                 });
//             }
//         },
//     };
//     informationCookie.start();
// };

const scrollTopIcon = () => {
  //
  const scrollTop = $('[data-scroll-top]')
  if (scrollTop) {
    scrollTop.on('click', function () {
      $('html, body').animate(
        {
          scrollTop: 0,
        },
        1000,
      )
    })

    // ikonka scrollTop pojawia się, gdy top menu jest sticky
    const topMenu = $('.main-menu__top')[0]
    $(window).on('scroll', function () {
      if (topMenu.classList.contains('is-sticky')) {
        scrollTop.addClass('is-active')
      } else {
        scrollTop.removeClass('is-active')
      }
    })
  }
}

const articleFloatingWindows = () => {
  //funkcja odpowiedzialna za pozycjonowanie okienek z newsletterem i social media w artykule
  const articleFloatingWindow = $('.newsletter-and-socials')
  if (articleFloatingWindow.length > 0) {
    const changeWindowPosition = () => {
      const scrolled = $(window).scrollTop()
      const postContentPos = $('.post-page__content')[0].getBoundingClientRect()
      const postContentX = postContentPos.top
      const postContentY = postContentPos.right
      articleFloatingWindow.css('left', postContentY + 30 + 'px')
      if (
        scrolled + postContentX + articleFloatingWindow.height() >
        window.innerHeight - 1
      ) {
        articleFloatingWindow.css({
          top: '',
          bottom: '0px',
        })
      } else {
        articleFloatingWindow.css({
          bottom: '',
          top: scrolled + postContentX + 'px',
        })
      }
    }

    // changeWindowPosition();
    // $(window).on("scroll resize", changeWindowPosition);
  }
}

export {
  checkMobile,
  isMobileWindow,
  linksDisabled,
  hamburger,
  footerMenu,
  aboutUsText,
  stickyMainMenu,
  removeVideoCovering,
  scrollToSection,
  playVideo,
  readMore,
  scrollTopIcon,
  articleFloatingWindows,
  setHeightPostHeader,
  loadLessButtonScroll
}
