import { changeCurrentFormDatasets } from "../gtm-datalayer-pushes/gtm-datalayer-pushes";

const elem = $(".form-modal");
const modalStep = $(".form-step-js");
const modalOfferLink = $(".open-offer-form-js");
const modalHelpLink = $(".open-help-form-js");
const step1 = $("#step-1");
const step2 = $("#step-2");
const step3 = $("#step-3");
const step4 = $("#step-3");
const stepBack = $(".step-back-js");

const resetAllFormInputs = (form) => {
    form.find("input")
        .not(".js-input-hidden")
        .each(function () {
            $(this).val("");
            $(this).removeClass("error not-empty");
        });
    form.find("textarea").each(function () {
        $(this).val("");
        $(this).removeClass("error not-empty");
    });
    form.find("[type=checkbox]").each(function () {
        $(this).prop("checked", false);
        $(this).removeClass("error");
    });
};

const modalForm = () => {
    const elemOpen = $("[data-elem='form-box']");
    const elemOpen2a = $("[data-elem='form-box-2a']");
    const elemOpen2b = $("[data-elem='form-box-2b']");

    const elemClose = $(".js-modal-close");
    const modalWithSelect = $(".form-modal.modal-with-select");
    const allModalForms = $(".form-modal");
    const toggle = (e, state) => {
        e.preventDefault();
        if (state) {
            step1.addClass("is-active");
            step4.removeClass("is-active");
            modalWithSelect.addClass("is-active");
            $("body").css("overflow", "hidden");
        } else {
            allModalForms.removeClass("is-active");
            step1.removeClass("is-active");
            resetAllFormInputs(allModalForms);
            $("body").css("overflow", "");
        }
    };
    $(elemOpen).length > 0 && $(elemOpen).on("click", (e) => toggle(e, true));
    $(elemClose).on("click", (e) => toggle(e, false));

    const basic2a = (e) => {
        e.preventDefault();
        modalWithSelect.addClass("is-active");
        step2.addClass("is-active");
        step3.removeClass("is-active");
    };
    const basic2b = (e) => {
        e.preventDefault();
        modalWithSelect.addClass("is-active");
        step3.addClass("is-active");
        step2.removeClass("is-active");
    };
    $(elemOpen2a).length > 0 && $(elemOpen2a).on("click", (e) => basic2a(e));
    $(elemOpen2b).length > 0 && $(elemOpen2b).on("click", (e) => basic2b(e));
};

const modalSteps = () => {
    var changeStep = function (step) {
        step.siblings().removeClass("is-active");
        step.addClass("is-active");
    };
    modalOfferLink.click(function (e) {
        changeCurrentFormDatasets(modalOfferLink[0].dataset);
        e.preventDefault();
        changeStep(step2);
    });

    modalHelpLink.click(function (e) {
        changeCurrentFormDatasets(modalHelpLink[0].dataset);
        e.preventDefault();
        changeStep(step3);
    });

    stepBack.click(function (e) {
        e.preventDefault();
        changeStep(step1);
    });
};

export { modalForm, modalSteps };
