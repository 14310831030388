const createMap = () => {
    
    let elementsForMaps = document.getElementsByClassName("modal__map");
    
    Object.keys(elementsForMaps).forEach(function (key) {
        
        let elementForMap = elementsForMaps[key];

        var elementForMarker = elementForMap.getElementsByClassName("modal__map-marker")[0];
        
        if (elementForMarker) {
            var infoText = elementForMarker.getElementsByClassName("modal__map-infowindow")[0];
        }

        if (elementForMarker) {
            let elLat = elementForMarker.getAttribute('data-lat');
            let elLng = elementForMarker.getAttribute('data-lng');
            if ( elLat !== '' && elLng !== '' ) {
                let lat = parseFloat(elementForMarker.getAttribute('data-lat'));
                let lng = parseFloat(elementForMarker.getAttribute('data-lng'));
                var location = { lat: lat, lng: lng };
            };
            initMap(elementsForMaps[key], elementForMarker, location, infoText);
        }
    });
};

export default createMap;

const initMap = (elementMap, elementForMarker, location, infoText) => {

    const stylers = [
        {
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#f5f5f5"
            }
        ]
        },
        {
            "elementType": "labels.icon",
            "stylers": [
            {
                "visibility": "off"
            }
            ]
        },
        {
            "elementType": "labels.text.fill",
            "stylers": [
            {
                "color": "#616161"
            }
            ]
        },
        {
            "elementType": "labels.text.stroke",
            "stylers": [
            {
                "color": "#f5f5f5"
            }
            ]
        },
        {
            "featureType": "administrative.land_parcel",
            "elementType": "labels.text.fill",
            "stylers": [
            {
                "color": "#bdbdbd"
            }
            ]
        },
        {
            "featureType": "poi",
            "elementType": "geometry",
            "stylers": [
            {
                "color": "#eeeeee"
            }
            ]
        },
        {
            "featureType": "poi",
            "elementType": "labels.text.fill",
            "stylers": [
            {
                "color": "#757575"
            }
            ]
        },
        {
            "featureType": "poi.park",
            "elementType": "geometry",
            "stylers": [
            {
                "color": "#e5e5e5"
            }
            ]
        },
        {
            "featureType": "poi.park",
            "elementType": "labels.text.fill",
            "stylers": [
            {
                "color": "#9e9e9e"
            }
            ]
        },
        {
            "featureType": "road",
            "elementType": "geometry",
            "stylers": [
            {
                "color": "#ffffff"
            }
            ]
        },
        {
            "featureType": "road.arterial",
            "elementType": "labels.text.fill",
            "stylers": [
            {
                "color": "#757575"
            }
            ]
        },
        {
            "featureType": "road.highway",
            "elementType": "geometry",
            "stylers": [
            {
                "color": "#dadada"
            }
            ]
        },
        {
            "featureType": "road.highway",
            "elementType": "labels.text.fill",
            "stylers": [
            {
                "color": "#616161"
            }
            ]
        },
        {
            "featureType": "road.local",
            "elementType": "labels.text.fill",
            "stylers": [
            {
                "color": "#9e9e9e"
            }
            ]
        },
        {
            "featureType": "transit.line",
            "elementType": "geometry",
            "stylers": [
            {
                "color": "#e5e5e5"
            }
            ]
        },
        {
            "featureType": "transit.station",
            "elementType": "geometry",
            "stylers": [
            {
                "color": "#eeeeee"
            }
            ]
        },
        {
            "featureType": "water",
            "elementType": "geometry",
            "stylers": [
            {
                "color": "#c9c9c9"
            }
            ]
        },
        {
            "featureType": "water",
            "elementType": "labels.text.fill",
            "stylers": [
            {
                "color": "#9e9e9e"
            }
            ]
    }];
    const mapOptions = {
        zoom: 13,
        center: location,
        mapTypeId: google.maps.MapTypeId.ROADMAP,
        styles: stylers,
        fullscreenControl: false,
        disableDefaultUI: false,
        zoomControl: true,
        zoomControlOptions: {
            style: google.maps.ZoomControlStyle.SMALL
        },
    };
    
    var map = new google.maps.Map(elementMap, mapOptions);
    
    initMarker(elementForMarker, map, location, infoText);

    // Return map instance.
    return map;
};

const initMarker = (elementForMarker, map, location, infoText) => {
    const MAP_MARKER = 'M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z';
    
    const image = { 
        path: MAP_MARKER,
        fillColor: '#e20074',
        strokeColor: '#e20074',
        fillOpacity: 1,
        scale: 3,
        anchor: new google.maps.Point(10,20),
    };

    var marker = new google.maps.Marker({
        position: location,
        map: map,
        icon: image,
        animation: google.maps.Animation.DROP,
    });

    // If marker contains infoText, add it to an infoWindow.
    if ( infoText ) {
        // Create info window.
        var infowindow = new google.maps.InfoWindow({
            content: elementForMarker.innerHTML,
        });
        // Show info window when marker is clicked.
        google.maps.event.addListener(marker, 'click', function() {
            infowindow.open( map, marker );
        });
    };
};