export const nis = () => {
    nisTest();
    nisTabs();
    eventsScroll();
}

const changeStepTest = (steps, activeStep, buttonPrev) => {
    const toHide = [];
    const toOpen = [];

    for (const step of steps) {
        if (step.dataset.step == activeStep) {
            toOpen.push(step);
            continue;
        }

        toHide.push(step);
    }

    for (const step of toHide) {
        step.classList.remove('active');
    }

    for (const step of toOpen) {
        step.classList.add('active');
    }

    buttonPrev.classList.remove('prev', 'reset');

    if (activeStep > 1 && activeStep < 6) {
        buttonPrev.classList.add('prev');
        buttonPrev.dataset.step = activeStep - 1;
    }

    if (activeStep === 6) {
        buttonPrev.classList.add('reset');
        buttonPrev.dataset.step = 1;
    }

}

const nisTest = () => {
    const nisTestElements = document.querySelectorAll('.test-nis');

    if (!nisTestElements.length) {
        return;
    }

    for (const nisTestElement of nisTestElements) {
        const buttons = nisTestElement.querySelectorAll('.js-button-test-nis');
        const stepsElements = nisTestElement.querySelectorAll('.test-nis__step');
        const resuls = nisTestElement.querySelectorAll('.test-nis__step-result');
        const buttonPrev = nisTestElement.querySelector('.js-back');

        for (const button of buttons) {
            button.addEventListener('click', (e) => {
                e.preventDefault();

                const buttonStep = button.dataset.button;

                if (buttonStep === 'end') {
                    resuls[1].classList.add('active');
                    resuls[0].classList.remove('active');
                    changeStepTest(stepsElements, 6, buttonPrev);
                    return;
                }

                if (buttonStep === 'true') {
                    resuls[0].classList.add('active');
                    resuls[1].classList.remove('active');

                    changeStepTest(stepsElements, 6, buttonPrev);
                    return;
                }

                const stepNextActive = parseInt(button.closest('.test-nis__step').dataset.step) + 1;
                changeStepTest(stepsElements, stepNextActive, buttonPrev);
            });
        }

        buttonPrev.addEventListener('click', (e) => {
            e.preventDefault();
            if (buttonPrev.dataset.step == 1) {
                resuls[0].classList.remove('active');
                resuls[1].classList.add('active');
            }

            changeStepTest(stepsElements, buttonPrev.dataset.step, buttonPrev);
        });
    }
}

const nisTabs = () => {
    const sections = document.querySelectorAll('.sektory-nis');

    if (!sections.length) {
        return;
    }

    for (const section of sections) {
        const swtichers = section.querySelectorAll('.js-switcher-tabs-nis');
        const tabs = section.querySelectorAll('.js-switch-nis');

        if (!swtichers.length || !tabs.length) {
            return;
        }

        for (const switchEl of swtichers) {
            switchEl.addEventListener('click', (e) => {
                e.preventDefault();
                let tabActive, navActive;

                if (switchEl.classList.contains('active')) {
                    return;
                }

                const dataId = switchEl.dataset.switch;

                for (const el of swtichers) {
                    el.classList.remove('active');

                    if (el.dataset.switch === dataId) {
                        navActive = el;
                    }
                }

                for (const el of tabs) {
                    el.classList.remove('active');

                    if (el.dataset.switch === dataId) {
                        tabActive = el;
                    }
                }

                navActive.classList.add('active');
                tabActive.classList.add('active');

            });
        }
    }
}

const pointerScroll = (elem) => {

    const dragStart = (ev) => elem.setPointerCapture(ev.pointerId);
    const dragEnd = (ev) => elem.releasePointerCapture(ev.pointerId);
    const drag = (ev) => elem.hasPointerCapture(ev.pointerId) && (elem.scrollLeft -= ev.movementX);

    elem.addEventListener("pointerdown", dragStart);
    elem.addEventListener("pointerup", dragEnd);
    elem.addEventListener("pointermove", drag);
};

const eventsScroll = () => {
    const elements = document.querySelectorAll('.wydarzenia-nis__events');

    if (!elements.length) {
        return;
    }

    for (const el of elements) {
        pointerScroll(el);
    }
}