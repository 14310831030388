import "./vendors/slick.js";

export const videoSectionCarousel = () => {
	const $videoSection = $('.section-video-carousel');
	const $videoSectionSlider = $('.section-video-carousel-js');
	const $videoStartBtn = $('.video-active-carousel-js');
	const $videoStopBtn = $('.video-close-carousel-js');
	const Player = document.getElementById('playerYT-carousel');
	let times = 0, playY;

	var stopVideo = () => {
		Player.src = '';
		times = 0;
	}
	var playVideo = (idMovie) => {
		if (times == 0) {
			playY = Player.src = 'https://www.youtube-nocookie.com/embed/' + idMovie + '?showinfo=0&frameborder=0&allowfullscreen=allowfullscreen&showinfo=0&autoplay=1';
			times = 1;
		}
	}

	if ($videoSectionSlider.length) {
		$($videoSectionSlider).slick({
			autoplay: true,
			autoplaySpeed: $($videoSectionSlider).attr('data-speed'),
			pauseOnHover: false,
			arrows: false,
			dots: true
		});

		$($videoSection).show();
	}

	$videoStartBtn.click(
		function (e) {
			e.preventDefault();
			const idMovie = $(this).attr('data-src');
			playVideo(idMovie);
			$videoSection.addClass('video-active');
			$($videoSectionSlider).slick('slickPause');
		}
	);

	$videoStopBtn.click(
		function (e) {
			e.preventDefault();
			$videoSection.removeClass('video-active');
			stopVideo();
			$($videoSectionSlider).slick('slickPlay');
		}
	);
}
