import ajaxLoad from "../ajax/ajax-load";
import Accordion from "../accordion/accordion";
import modal from "../modal";

class ClientsMenu {
    constructor() {
        this.nonce = $('.for-clients').find('input[name=nonce_posts]').val();
        this.mq = window.matchMedia("(max-width: 1199px)");
        this.root = $('[data-elem="rootElem"]');
        this.menuElem = $('[data-elem="menuElem"]');
        this.menuHeadElems = $('[data-head="menu-head"]');
        this.submenuElem = $('[data-elem="submenuElem"]');
        this.childColumns = $('[data-elem="childColumn"]');
        this.backElem = $('[data-elem="backElem"]');
        this.column = $('[data-elem="columnElem"]');
        this.ajaxWrapper = $('[data-set="ajax-wrapper"]');
        this.loader = $('[data-elem="ajax-loader"]');
        this.startContentDesktop = $('[data-elem="infoForClientsD"]');
        this.startContentMobile = $('[data-elem="infoForClientsM"]');
        this.banners = $("[data-elem='banners']");

        if (
            this.root.length > 0 &&
            this.menuElem.length > 0 &&
            this.submenuElem.length > 0 &&
            this.backElem.length > 0
        ) {
            this.init();
        } else {
            return;
        }
    }
    init() {
        this.checkMedia(this.mq);
        this.mq.addListener(this.checkMedia.bind(this));
        this.resetOnResize();

    }

    reset() {
        if (
            this.root.length > 0 &&
            this.menuElem.length > 0 &&
            this.submenuElem.length > 0 &&
            this.backElem.length > 0
        ) {
            this.addCss(0);
            this.toggleActiveClasses(this.menuElem.parent());
            this.toggleActiveClasses(this.submenuElem.parent());
            this.setToggleRoot(false);
            this.childColumns.hide();

            this.toggleActiveClasses(this.startContentDesktop.eq(0), false);
            this.toggleActiveClasses(this.startContentMobile.eq(0), false);
            this.toggleActiveClasses(this.startContentDesktop.eq(1), true);
            this.toggleActiveClasses(this.startContentMobile.eq(1), true);
            this.menuHeadElems.removeClass("isActive");
            this.menuHeadElems.removeClass("wasActive");
            this.menuHeadElems.eq(0).addClass("isActive");
            this.banners.addClass("isActive");

            console.log(this.menuHeadElems + "dafca");
        }
    }
    resetOnResize() {
        const width = $(window).outerWidth();
        $(window).on("resize", () => {
            if (
                $(window).outerWidth() !== width &&
                $(window).outerWidth() < 1200
            ) {
                this.reset();
            }
        });
    }
    checkMedia(mq) {

        if (mq.matches) {
            this.addEvents();

        } else {
            this.removeEvents();
        }

    }

    onContentChange(e, next) {
        const target = $(e.currentTarget);
        const col = target.closest('[data-elem="columnElem"]');
        const index = col.index();
        if (index === 0 && next) {
            this.toggleActiveClasses(this.startContentDesktop.eq(index), true);
            this.toggleActiveClasses(this.startContentMobile.eq(index), true);
            this.toggleActiveClasses(
                this.startContentDesktop.eq(index + 1),
                false
            );
            this.toggleActiveClasses(
                this.startContentMobile.eq(index + 1),
                false
            );
        } else if (index === 1 && !next) {
            this.toggleActiveClasses(this.startContentDesktop.eq(index), true);
            this.toggleActiveClasses(this.startContentMobile.eq(index), true);
            this.toggleActiveClasses(
                this.startContentDesktop.eq(index - 1),
                false
            );
            this.toggleActiveClasses(
                this.startContentMobile.eq(index - 1),
                false
            );
        } else if (index === 1 && next) {
            this.toggleActiveClasses(this.startContentDesktop.eq(index), true);
            this.toggleActiveClasses(this.startContentMobile.eq(index), true);
            this.toggleActiveClasses(
                this.startContentDesktop.eq(index - 1),
                true
            );
            this.toggleActiveClasses(
                this.startContentMobile.eq(index - 1),
                true
            );
        } else if (index === 2 && !next) {
            this.toggleActiveClasses(
                this.startContentDesktop.eq(index - 1),
                false
            );
            this.toggleActiveClasses(
                this.startContentMobile.eq(index - 1),
                false
            );
            this.toggleActiveClasses(
                this.startContentDesktop.eq(index - 2),
                true
            );
            this.toggleActiveClasses(
                this.startContentMobile.eq(index - 2),
                true
            );
        }
    }
    onSlideHandler(e, next = true) {
        const target = $(e.currentTarget);
        const width = this.column.innerWidth();
        const col = target.closest('[data-elem="columnElem"]');
        const index = col.index('[data-elem="columnElem"]');

        next && index === 0 && this.addCss(-width);
        next && index === 1 && this.addCss(-width * 2);

        !next && index === 2 && this.addCss(-width);
        !next && index === 1 && this.addCss(0);
    }

    addEvents() {
        this.addCss(0, { transition: "transform .35s ease" });
        this.menuElem.on("click", (e) => this.menuHandlers.call(this, e));
        this.backElem.on("click", (e) => {
            this.onSlideHandler.call(this, e, false);
            this.onContentChange.call(this, e, false);
        }
        );
        this.submenuElem.on("click", (e) => this.submenuHandlers.call(this, e));
    }

    removeEvents() {
        this.addCss(0, { transition: "none", transform: `translateX( 0 )` });

        this.menuElem.off("click");
        this.menuElem.on("click", (e) =>
            this.menuDesktopHandlers.call(this, e)
        );

        this.backElem.off("click");

        this.submenuElem.off("click");
        this.submenuElem.on("click", (e) =>
            this.submenuDesktopHandlers.call(this, e)
        );
    }

    addCss(value, keys = {}) {
        this.root.css({ transform: `translateX( ${value}px )`, ...keys });
    }

    setLoader(hide) {
        hide === true
            ? this.loader.css("display", "flex").fadeOut()
            : this.loader.css("display", "flex").show();
    }
    async ajax(elem) {
        try {
            await ajaxLoad(elem.data("id"), this.ajaxWrapper, this.nonce);
            this.setLoader(true);
            this.setToggleRoot();
            new Accordion();

        } catch {
            this.setLoader(true);
            this.setToggleRoot();
        }
    }

    setToggleRoot(add = true) {
        if (add) {
            this.root.addClass("isActive");
        } else {
            this.ajaxWrapper.html("");
            this.root.removeClass("isActive");
        }
    }

    setActiveClasses(elem, allElems) {
        const allLi = allElems.parent();
        const thatLi = elem.parent();
        const nextParent = thatLi.closest('[data-elem="columnElem"]').next();
        const currentParent = thatLi.closest('[data-elem="columnElem"]');

        const wasHead = currentParent.find('[data-head="menu-head"]');
        const activeHead = nextParent.find('[data-head="menu-head"]');
        this.menuHeadElems.removeClass("isActive");

        wasHead.addClass("wasActive");
        activeHead.addClass("isActive");

        allLi.removeClass("isActive");
        !thatLi.hasClass("isActive") && thatLi.addClass("isActive");

    }
    toggleActiveClasses(elems, toggle = true) {
        toggle ? elems.removeClass("isActive") : elems.addClass("isActive");
    }

    submenuHandlers(e) {
        e.preventDefault();
        const current = $(e.currentTarget);
        this.onSlideHandler(e);
        this.setLoader(false);
        this.ajax(current, true);
        this.setToggleRoot();
        this.setActiveClasses(current, this.submenuElem);
        this.onContentChange(e, true);
        this.banners.addClass("isActive");

    }

    submenuDesktopHandlers(e) {
        e.preventDefault();
        const current = $(e.currentTarget);
        const col = current.closest('[data-elem="columnElem"]');
        const index = col.index();

        this.setLoader(false);
        this.setToggleRoot();
        this.setActiveClasses(current, this.submenuElem);
        this.ajax($(e.currentTarget), true);
        this.onContentChange(e, true);
        this.banners.addClass("isActive");
    }

    menuHandlers(e) {
        e.preventDefault();
        this.onSlideHandler(e);
        this.showColumn(e);
        this.setActiveClasses($(e.currentTarget), this.menuElem);
        this.onContentChange(e, true);
        this.banners.removeClass("isActive");
        window.scrollTo(50, 0);
    }

    menuDesktopHandlers(e) {
        e.preventDefault();
        this.showColumn(e);
        this.setActiveClasses($(e.currentTarget), this.menuElem);
        this.onContentChange(e, true);
        this.setToggleRoot(false);
        this.banners.removeClass("isActive");

    }

    showColumn(e) {
        const target = $(e.currentTarget);
        const index = target.parent().index();
        const elem = this.childColumns.eq(index);
        const elemChildLength = this.childColumns.eq(index).children().length;

        this.childColumns.hide();

        if (elemChildLength < 2) {
            this.childColumns.eq(index).find('[data-elem="submenuElem"]').trigger("click");          
            setTimeout(function () {
                $('[data-elem="infoForClientsD"].isActive').removeClass('isActive');
            }, 100);
        }         
        elem.show();
    }
}

export default ClientsMenu;
