const getPageNameFromUrl = () => {
  const urlParts = window.location.href.split('/')
  const length = urlParts.length

  const pageName = (urlParts[length - 1] != ''
    ? urlParts[length - 1]
    : urlParts[length - 2]
  )
    .split('?')[0]
    .split('&')[0]
    .replace(/-/g, ' ')
    .replace(/#/g, '')

  return pageName
}

export const gtmDatalayerPushes = () => {
  const barsAndBubbles = document.querySelectorAll('[data-element-type]')

  const helpForm = document.querySelector('.open-help-form-js')
  const offerForm = document.querySelector('.open-offer-form-js')

  if (getPageNameFromUrl() == 'kontakt' || getPageNameFromUrl() == 'contact') {
    helpForm.dataset.goalName = 'Formularz kontaktowy - pomoc'
    offerForm.dataset.goalName = 'Formularz kontaktowy - oferta'
  } else {
    if (helpForm && offerForm) {
      helpForm.dataset.goalName = 'Formularz kontaktowy'
      offerForm.dataset.goalName = 'Formularz produktowy'
    }
  }

  barsAndBubbles.forEach((elem) =>
    elem.addEventListener('click', () => {
      if (helpForm && offerForm) {
        const helpPrefix = 'Formularz kontaktowy - '
        const offerPrefix = 'Formularz produktowy - '

        const helpGoalName = helpPrefix + elem.dataset.elementType
        const offerGoalName = offerPrefix + elem.dataset.elementType

        helpForm.dataset.goalName = helpGoalName
        offerForm.dataset.goalName = offerGoalName
      }
    }),
  )
}

export const changeCurrentFormDatasets = (dataset) => {
  const goalName = dataset.goalName
  const formName = dataset.formName
    .replace(' <br>', ' ')
    .replace('<br>', ' ')
    .replace(' <br />', ' ')
    .replace('<br />', ' ')
    .replace(' </br>', ' ')
    .replace('</br>', ' ')
    .replace(/\n/g, '')
    .replace(/%/g, '')

  const dataLayerObjectToPush = {
    event: 'formView',
    goalName,
    formName,
  }

  console.log(dataLayerObjectToPush)

  window.dataLayer.push(dataLayerObjectToPush)
  // console.log(window.dataLayer);

  window.goalName = goalName
  window.formName = formName
}
