import clientsModals from "../clientsModals/clientsModals";
import modalAjax from "../modal-ajax";
const ajaxLoad = async (elemid, wrapper, nonce) => {
  const wrap = wrapper;
  const id = elemid; // elem.data('id');
  const key = nonce;

  const result = await $.ajax({
    type: "POST",
    /**
     *
     * TODO - change link to global variable
     */
    url: "/wp-admin/admin-ajax.php",
    data: {
      action: "load_post",
      id,
      key,
    },
    success: (req) => {
      wrap.html(JSON.parse(req));
      clientsModals(document.querySelector('[data-set="ajax-wrapper"]'));
    },
    complete: function (data) {
      modalAjax(document.querySelector('[data-set="ajax-wrapper"]'));
    },
    error: (req, status, error) => {
      wrap.html(error);
      console.log(error);
    },
  });
  return result;
};

export default ajaxLoad;
