class Accordion {
    constructor() {
        this.root = $('[data-set="ajax-wrapper"]');
        this._init();
    }

    _onToggle(e) {
        const $that = $(e.currentTarget);
        const root = $that.parent();
        const body = $that.next();
        const innerBody = body.find('[data-elem="inner-body"]');
        const open = root.hasClass("isActive");
        !open ? root.addClass("isActive") : root.removeClass("isActive");
        if (!open) {
            body.css("max-height", 0);
            body.css("max-height", innerBody.outerHeight());
        } else {
            body.css("max-height", 0);
            this._stopAllYouTubeVideos(root);
        }
    }

    _init() {
        this._catchYoutubeVideo();
        this.accordion = $('[data-elem="accordion-header"]');
        this.accordion.off("click");
        this.accordion.on("click", this._onToggle.bind(this));
    }
    _catchYoutubeVideo() {
        const videos = $(".for-clients-article__entry-content .wp-block-embed-youtube .wp-block-embed__wrapper");
        videos.each((i, v) => {
            const url = $(v).text();
            const youtube = new URL(url).searchParams.get("v");
            $(v).html(
                `<iframe class="video" src="https://www.youtube.com/embed/${youtube}?enablejsapi=1" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen="true" ></iframe>`
            );
        });
    }
    _stopAllYouTubeVideos(root) {
        var iframes = root.find("iframe");
        iframes.each((i, iframe) => {
            iframe.contentWindow.postMessage(
                JSON.stringify({ event: "command", func: "stopVideo" }),
                "*"
            );
        });
    }
}

export default Accordion;
