const calculatorCSB = document.querySelector('.section-product-calculator-csb');

const CSBCalculator = () => {
    if (calculatorCSB){
        const modalInfo =  document.querySelector('.js-modal.csb-calculator-modal');
        const modalInfoClose =  document.querySelector('.js-modal.csb-calculator-modal .form-modal__close-icon');
        const allInputButtons = document.querySelectorAll('.input-plus-minus__button');
        const agreementDuration = document.getElementById('bottom-calculator__agreement-duration');
        const allPackagesInputs = document.querySelectorAll('.calculator-csb__package');
        const allMicrosoftPackagesInputs = document.querySelectorAll('.calculator-csb__microsoft-package');
        const allCSBInputs = document.querySelectorAll('.calculator-csb__csb-package');
        const packagesTotal = document.getElementById('bottom-calculator__packages-total');
        const priceTotal = document.getElementById('bottom-calculator__price-total');
        const allMicrosoftPackagesTotal= document.getElementById('calculator-csb__m365-total');
        const allPackagesTotal= document.getElementById('calculator-csb__desktops-total');
        const allCSBTotal= document.getElementById('calculator-csb__csb-total');

        let allPackages = 0;
        let allPackagesPrice = 0;
        let allPackagesPriceNotPrecise = 0;
        let allMicrosoftPackages = 0;
        let allMicrosoftPackagesOrdered = 0;
        let allMicrosoftPackagesPrice = 0;
        let allMicrosoftPackagesPriceNotPrecise = 0;
        let allCSBPackages = 0;
        let allCSBPackagesPrice = 0;
        let allCSBPackagesPriceNotPrecise = 0;
        let agreementDurationValue = agreementDuration.value;
        let test = 1;

        function showModalInfo(){
            modalInfo.classList.add('is-active');
        }

        function hideModalInfo(){
            modalInfo.classList.remove('is-active');
        }

        modalInfoClose.addEventListener('click', function(){
            hideModalInfo();
        })

        function ParseFloat(string) {
            return parseFloat(string).toFixed(2);
        }

        function toFixed(value, precision) {
            var power = Math.pow(10, precision || 0);
            return Number(Math.round(value * power) / power);
        }

        allInputButtons.forEach((inputButton) => {
            inputButton.addEventListener('click', () => {
                let step;
                let maxValue;
                let minValue;
                let limitByWorkspace;
                let column;

                if (inputButton.dataset.buttonType === 'minus'){

                    let inputAfterButton = inputButton.nextElementSibling;
                    let packageType = inputAfterButton.dataset.packagetype;

                    switch(packageType){
                        case 'workspace':
                            if (allPackages == allMicrosoftPackages || allPackages == allCSBPackages ) {
                                showModalInfo()
                             } else subtract();
                        break;
                        case 'microsoft':
                            subtract();
                        break;
                        case 'csb':
                            subtract();
                        break;
                        case 'agreement-duration':
                            subtract();
                        break;
                    }
                    function subtract(){
                        step = parseInt(inputAfterButton.dataset.step);
                        minValue = toFixed(inputAfterButton.dataset.minValue ? inputAfterButton.dataset.minValue : 0, 2);
                        inputAfterButton.value = parseInt(inputAfterButton.value) > minValue ? parseInt(inputAfterButton.value) - step : parseInt(inputAfterButton.value);
                        if (inputAfterButton.id === "bottom-calculator__agreement-duration"){
                            agreementDurationValue = toFixed(inputAfterButton.value, 2);
                        }    
                    }
                }

                if (inputButton.dataset.buttonType === 'plus'){
                    let inputBeforeButton = inputButton.previousElementSibling;
                    limitByWorkspace = toFixed(inputBeforeButton.dataset.limitbyworkspace == '1' ? true : false, 2);
                    column = inputBeforeButton.dataset.column;
                    step = parseInt(inputBeforeButton.dataset.step);
                    let packageType = inputBeforeButton.dataset.packagetype;
                    let allThisTypePackages = 0;
                    let allThisTypePackagesInputs;

                    switch(packageType){
                        case 'microsoft':
                            allThisTypePackagesInputs = allMicrosoftPackagesInputs;
                        break;
                        case 'csb':
                            allThisTypePackagesInputs = allCSBInputs;
                        break;
                    }

                    if (limitByWorkspace){
                        allThisTypePackages = 0;

                        allThisTypePackagesInputs.forEach((allThisTypePackageInput) => {
                            allThisTypePackages += parseInt(allThisTypePackageInput.value);
                        });
                        maxValue = allPackages;

                        if (allThisTypePackages < allPackages){
                            inputBeforeButton.value = parseInt(inputBeforeButton.value) + step;
                        } else {
                            inputBeforeButton.value = parseInt(inputBeforeButton.value);
                        }

                        allThisTypePackages = 0;
                        allThisTypePackagesInputs.forEach((allThisTypePackageInput) => {
                            allThisTypePackages += parseInt(allThisTypePackageInput.value);
                        });
                    }

                    if (!limitByWorkspace){
                        maxValue = toFixed(inputBeforeButton.dataset.maxValue ? inputBeforeButton.dataset.maxValue : 1000, 2);
                        inputBeforeButton.value = parseInt(inputBeforeButton.value) <= maxValue - step ? parseInt(inputBeforeButton.value) + step : parseInt(inputBeforeButton.value);
                    }
                    
                    if (inputBeforeButton.id === "bottom-calculator__agreement-duration"){
                        agreementDurationValue = toFixed(inputBeforeButton.value, 2);
                    }
                }
                calculateCSB();
            })
        })

        

        const calculateCSB = () => {
            allPackages = 0;
            allMicrosoftPackages = 0;
            allCSBPackages = 0;
            allMicrosoftPackagesTotal.value = 0;
            allPackagesTotal.value = 0;
            allCSBTotal.value = 0;
            packagesTotal.value = 0;
            priceTotal.value = 0;
            allPackagesPrice = 0;
            allPackagesPriceNotPrecise = 0;
            allMicrosoftPackagesPrice = 0;
            allMicrosoftPackagesPriceNotPrecise = 0;
            allCSBPackagesPrice = 0;
            allCSBPackagesPriceNotPrecise = 0;

            allPackagesInputs.forEach((packageInput) => {
                allPackages += parseInt(packageInput.value);
                allPackagesPriceNotPrecise = ParseFloat(packageInput.dataset[`price-${agreementDurationValue}`]) * parseInt(packageInput.value)
                allPackagesPrice += toFixed(allPackagesPriceNotPrecise, 2);
                allPackagesTotal.value = allPackages;
            })

            allMicrosoftPackagesInputs.forEach((microsoftPackageInput) => {
                allMicrosoftPackages += parseInt(microsoftPackageInput.value);
                allMicrosoftPackagesPriceNotPrecise = ParseFloat(microsoftPackageInput.dataset[`price-${agreementDurationValue}`]) * parseInt(microsoftPackageInput.value)
                allMicrosoftPackagesPrice += toFixed(allMicrosoftPackagesPriceNotPrecise, 2);
                allMicrosoftPackagesTotal.value = allMicrosoftPackages;
            })

            allCSBInputs.forEach((allCSBInput) => {
                allCSBPackages += parseInt(allCSBInput.value);
                allCSBPackagesPriceNotPrecise = ParseFloat(allCSBInput.dataset[`price-${agreementDurationValue}`]) * parseInt(allCSBInput.value);
                allCSBPackagesPrice += toFixed(allCSBPackagesPriceNotPrecise, 2);
                allCSBTotal.value = allCSBPackages;
            })

            packagesTotal.value = toFixed(allPackages, 2) + toFixed(allMicrosoftPackages, 2) + toFixed(allCSBPackages, 2);
            priceTotal.value = toFixed(allPackagesPrice, 2) + toFixed(allMicrosoftPackagesPrice, 2) + toFixed(allCSBPackagesPrice, 2);
        }

        calculateCSB();
    }
}

export default CSBCalculator;