import select2 from "./select2";

const selectInModal = () => {
	$('.select-modal-form-js').select2({
        containerCssClass: "select__container",
        dropdownCssClass: "select__options",
        width: 'resolve',
        dropdownParent: $('.modal-with-select'),
        minimumResultsForSearch: -1,
        placeholder: "$(this).data('placeholder')",
    });
};
export default selectInModal;
