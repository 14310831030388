const videoSection      = () => {
	const $videoSection = $( '.section-video' );
	const $videoStartBtn = $( '.video-active-js' );
	const $videoStopBtn = $( '.video-close-js' );
	const Player = document.getElementById('playerYT');
	let times = 0, playY;
	var stopVideo = () => { 
		playY = playY.slice(0, -11);
		Player.src = playY
		times = 0;
	  }
	  var playVideo = () => { 
		if(times == 0){
			playY = Player.src += '?showinfo=0&frameborder=0&allowfullscreen=allowfullscreen&showinfo=0&autoplay=1';
			times = 1;
		}
	  }
	
	$videoStartBtn.click(
		function(e){
			e.preventDefault();		
			playVideo();
			$videoSection.addClass( 'video-active' );
		}
	);
	$videoStopBtn.click(
		function(e){
			e.preventDefault();
			$videoSection.removeClass( 'video-active' );
			stopVideo(); 
		}
	);
	
}

export default videoSection;
