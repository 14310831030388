class Tabs {
    constructor(element) {
        this.tabs = element.querySelectorAll("[data-tab-id]");
        this.tabDescriptions = element.querySelectorAll("[data-tab-description-id]");

        this.tabs.forEach(this._addListener.bind(this));
    }

    _addListener(tab) {
        tab.addEventListener("click", this._handleTabClick.bind(this));
    }

    _handleTabClick(event) {
        const id = event.currentTarget.getAttribute("data-tab-id");
        this.tabs.forEach(function (element) {
            element.classList.remove("tab-box--active");
        });
        event.currentTarget.classList.add("tab-box--active");

        this._hideAllDescriptions();
        this.tabDescriptions.forEach(function (el) {
            if (el.getAttribute("data-tab-description-id") == id) {
                el.classList.add("box-description--active");
            }
        })
    }

    _hideAllDescriptions() {
        this.tabDescriptions.forEach(function (element) {
            element.classList.remove("box-description--active");
        });
    }

}

export default Tabs;