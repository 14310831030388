const SORT_TYPE = {
  DATE: 'date'
}

class PostsSort {
  constructor() {
    this.triggers = document.querySelectorAll('.js-sort-button');

    this.sortedByDate = false;

    this.addListeners = this.addListeners.bind(this);
    this.render = this.render.bind(this);

    this.init();
  }

  render(arr) {
    this.container.innerHTML = '';
    arr.map((item) => {
      this.container.insertAdjacentElement('beforeend', item);
    });
    this.container.insertAdjacentElement('beforeend', this.returnLink);
  }

  addListeners(evt) {
    evt.preventDefault();
    const sortType = evt.target.dataset.sortType;
    const newArr = [];
    this.posts.map((item) => newArr.push(item));
    switch (sortType) {
      case SORT_TYPE.DATE:
        if(!this.sortedByDate) {
          newArr.sort((a, b) => {
            const aTime = new Date(a.dataset.written).getTime();
            const bTime = new Date(b.dataset.written).getTime();
            if (aTime < bTime) {
              return -1;
            }
            if (aTime > bTime) {
              return 1;
            }
          })
          this.sortedByDate = true;
          this.render(newArr);
        } else {
          this.sortedByDate = false;
          this.render(this.posts);
        }
        break;
    }
  }

  init() {
    if(this.triggers) {
      this.container = document.querySelector('.js-sort-container');
      this.posts = Array.from(document.querySelectorAll('.js-post'));
      this.returnLink = document.querySelector('.js-return-link')

      this.triggers.forEach((item) => item.addEventListener('click', this.addListeners));
    }
  }
}

export default PostsSort
