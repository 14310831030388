import "./vendors/slick.js";

export const benefitsSectionSlider = () => {
	$('.js-benefits-slider').slick({
		dots: true,
		infinite: false,
		slidesToShow: 3,
		slidesToScroll: 3,
		centerMode: false, // Centering active slide
		variableWidth: false, // Allow sides to be partially visible
		prevArrow: '<button type="button" class="slick-prev">‹</button>',
		nextArrow: '<button type="button" class="slick-next">›</button>',
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 2,
					// variableWidth: true,
				}
			},
			{
				breakpoint: 768,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					// variableWidth: true,
				}
			}
		]
	});
}
